import RoomBadge from '../../shared/_room_badge';
import Popup from '../popups/popup';
import React, { Component } from 'react';
import LectureDescription from './lecture_description';

export default function CongressProgramSession(props) {
  const { session, properIcon } = props;
  const { events } = session;

  return (
    <div className='congress-program-extended__session congress-session'>
      <p className='congress-session__title-block'>
          <span className='congress-session__title'>
            {session.title}
          </span>
        {window.isMobileView && session.roomName &&
        <RoomBadge roomName={session.roomName}/>
        }
      </p>
      <div className='congress-program-extended__lectures'>
        {events && events.map((event, index) =>
          <CongressProgramSessionEvent event={event} key={index} properIcon={properIcon}/>
        )}
      </div>
    </div>
  )
}

class CongressProgramSessionEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturerPopupVisible: false
    }
  }

  _togglePopup = () => {
    this.setState({
      lecturerPopupVisible: !this.state.lecturerPopupVisible
    })
  };

  render() {
    const { event, properIcon } = this.props;
    const { timeOfEvent } = event;
    return (
      <div className='congress-lecture'>
        <div className='grid__col_2 grid__col_sm_1 grid__col_md_2'>
          {properIcon(event.eventType)}
        </div>
        <div className='grid__col_10 grid__col_sm_11 grid__col_md_10'>
          <div className="congress-lecture__time">
            {timeOfEvent}
          </div>
          <div className="congress-lecture__title">
            {event.title}
          </div>
          {event.lecturers.length > 0 && event.lecturers.map((lecturer, index) =>
            <div className="congress-lecture__lecturer" key={index} onClick={this._togglePopup}>
              {lecturer.nameWithDegree}
              {this.state.isPopupVisible &&
              <Popup data={lecturer}
                     type='lecturer'
                     popupShow={this.state.isPopupVisible}
                     closePopup={this._togglePopup}/>
              }
            </div>
          )}
          {event.description &&
            <LectureDescription description={event.description}/>
          }
        </div>
      </div>
    )
  }
}