import React, {Component} from 'react';
import moment from 'moment';
import HeaderSlider from './header_slider';
import HeaderTitleCard from './header_title_card';
import HeaderSticker from './header_sticker';
import HeaderBadge from './header_badge';


export default class Header extends Component {
  render() {
    const {conference, currentCityId, isCongressFuture, currentPlace} = this.props;

    const titleCardData = {
      desktopFontSize: conference.specializationFontSize,
      mobileFontSize: conference.specializationMobileFontSize,
      specialization: conference.specialization,
      title: conference.title,
      claim: conference.claim,
      startDate: moment(currentPlace.starting),
      endDate: moment(currentPlace.finishing),
      city: currentPlace.city,
      isCongressFuture: isCongressFuture
    };

    const badgeData = {
      educationalPoints: currentPlace.educationalPoints
    };

    const sliderData = {
      sliders: conference.sliders
    };

    const stickerData = {
      tickets: currentPlace.sections.filter(section => {return section.classification === 'ticket'}),
      marketingAlertHeader: currentPlace.marketingAlertHeader
    };

    return(
      <div className='header' id='header'>
        <HeaderSlider sliderData={sliderData}/>
        {stickerData.tickets.length > 0 &&
          <HeaderSticker
              data={stickerData.tickets[0]}
              marketingAlertHeader={stickerData.marketingAlertHeader}
          />
        }
        <HeaderTitleCard currentCityId={currentCityId}
                         titleCardData={titleCardData}/>
        {badgeData.educationalPoints > 0 && badgeData.educationalPoints !== null &&
          <HeaderBadge badgeData={badgeData}/>
        }
      </div>
    )
  }
}