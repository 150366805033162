import React, {Component} from 'react';
import AddToCartForm from './add_to_cart_form';
import { base_url } from '../../../api/Api';

export default class Entertainment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productSent: false
    }
  }

  handleSubmit = () => {
    return this.props.entertainmentProduct.sendToCart(1)
      .then(() => this.setState({productSent: true}))
      .catch(e => console.error(e))
  };

  render() {
    const { entertainmentProduct, header } = this.props;
    const { productSent } = this.state;
    const backgroundImage = {'backgroundImage': `url(${base_url}${entertainmentProduct.backgroundImage})`};

    return (
      <div className="entertainment">
        {!!header &&
        <p className="entertainment__title">
          {header}
        </p>
        }
        <div className={`ticket-offer__row ticket-purchase__input`}>
          <div className='entertainment__image' style={backgroundImage}/>
          <p className='entertainment__name'>
            {entertainmentProduct.name}
          </p>
          <p className='entertainment__price'>
            {Number(entertainmentProduct.baseDiscountPrice)} zł
          </p>
          <AddToCartForm handleSubmit={this.handleSubmit} productsSent={productSent}/>
        </div>
      </div>
    )
  }
}