import React, {Component} from 'react';
import {PdfIcon, PrintIcon} from '../../../assets/icons/icons';
import CongressProgramCollapsed from './congress_program_collapsed';
import CongressProgramExtended from './congress_program_extended';

export default class CongressProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extended: false,
    }
  }

  _handleProgramExtension = () => {
    this.setState({extended: !this.state.extended});
  };

  _print = (e) => {
    e.preventDefault();
    let w = window.open(e.target.href);
    w.print()
  };

  render() {
    const {program, currentPlace} = this.props;
    const {
      sessions,
      workshops,
      mainPageLectures,
      mainPageWorkshops,
      sessionDates,
      workshopDates,
      sessionsByRoomNames,
      sessionsRoomNames
    } = program;

    return (
        <div className='page__section congress-program' id='agenda'>
          <div className='congress-program__header'>
            <p className='text_nun-light section-title section-title_white'>
              Program {window.isSmallView ? '' : `- ${currentPlace.city}`}
            </p>
            <a href='#agenda' className='button button-flex congress-program__text'
                    onClick={this._handleProgramExtension}>
              {this.state.extended ? 'Zwiń' : 'Rozwiń pełny'} program
              <span className={`arrow arrow-sm ${this.state.extended ? 'arrow-sm_up' : 'arrow-sm_down'}`}/>
            </a>
          </div>
          <div className='congress-program__content'>

{/*            {window.isSmallView &&
              <div className='congress-program__align-right'>
                <button className='button button-flex congress-program-footer__button congress-program__share-button'>
                  Udostępnij
                  <ShareIcon iconTitle='ShareIcon' width='27' height='27' className='icon '/>
                </button>
              </div>
            }*/}

            {!this.state.extended &&
              <CongressProgramCollapsed
                  programData={program}
                  events={mainPageLectures}
                  workshops={mainPageWorkshops}
              />
            }

            {this.state.extended &&
              <CongressProgramExtended
                  sessionsByDays={sessions}
                  workshopsByDays={workshops}
                  sessionDates={sessionDates}
                  workshopDates={workshopDates}
                  sessionsByRoomNames={sessionsByRoomNames}
                  sessionsRoomNames={sessionsRoomNames}
                  program={program}
              />
            }
          </div>
          <div className='congress-program__footer congress-program-footer'>
            <div className='congress-program__icon-box'>
              <a href={program.pdfFile} target='_blank' rel="noopener noreferrer">
                <PdfIcon width='27' height='27' className='congress-program__icon'/>
              </a>
              <a href={program.pdfFile} className='congress-program__link-print' onClick={this._print} >
                <PrintIcon width='27' height='27' className='congress-program__icon-stroke'/>
              </a>
            </div>
            <a href='#agenda' className='button button-flex congress-program-footer__button congress-program-footer__button_bold'
                    onClick={this._handleProgramExtension}>
              {this.state.extended ? 'Zwiń' : 'Rozwiń pełny'} program {window.isSmallView ? '' : 'kongresu'}
              <span
                  className={`arrow arrow-sm ${this.state.extended ? 'arrow-sm_up' : 'arrow-sm_down'} congress-program-footer__arrow `}/>
            </a>
            <div className='congress-program__icon-box'>
{/*              <button className='button button-flex congress-program-footer__button'>
                Udostępnij
                <ShareIcon iconTitle='ShareIcon' width='27' height='27' className='icon congress-program-footer__icon'/>
              </button>*/}
            </div>
          </div>
        </div>
    )
  }
}

