import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../shared/_tooltip';
import Loader from '../../shared/_loader';

export default function AddToCartForm(props) {
  const {
    productsSent,
    tooltipVisible,
    withTooltip
  } = props;

  const [loading, setLoading] = useState(false);

  let tooltipContent, tooltipStyle;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    props.handleSubmit().then(() => {
      setLoading(false);
    })
  };

  if (withTooltip) {
    tooltipContent = 'Produkty zostały dodane do koszyka';
    tooltipStyle = {
      'backgroundColor': '#000',
      'color': '#FFF',
      'bottom': '150%',
      'top': 'unset'
    };
  }

  let loaderStyle = {
    'width': '14px',
    'height': '14px',
    'borderWidth': '1px',
    'borderColor': '#f2f2f2 #f2f2f2 #f2f2f2 #000',
    'alignSelf': 'center',
  };

  return (
    <form action="/" onSubmit={handleSubmit}>
      <button className={`button button-flex button-success-reverted ${productsSent ? 'button-success-reverted_disabled' : ''}`}
              type='submit'>
        {productsSent ? 'Dodano do koszyka' : 'Dodaj do koszyka'}
      </button>
      {loading &&
      <Loader style={loaderStyle}/>
      }
      {withTooltip &&
      <Tooltip content={tooltipContent} visible={tooltipVisible} tooltipStyle={tooltipStyle}/>
      }
    </form>
  )
}

AddToCartForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  productsSent: PropTypes.bool,
};
