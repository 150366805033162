import {Model} from '@itsfadnis/jsonapi-client';
import {Lecturers} from './Lecturers';
import { Parse } from '../../../helpers/parser';

export class ConferenceSectionAgendaSectionEvents extends Model {
  static _type = 'conference_section_agenda_section_events';

  constructor(args = {}) {
    super(args);

    this.id = args.id;
    this.title = args.title;
    this.starting = args.starting;
    this.finishing = args.finishing;
    this.eventType = args.eventType;
    this.showOnMainPage = args.showOnMainPage;
    this.lft = args.lft;
    this.rgt = args.rgt;
    this.price = args.price;
    this.description = args.description;
    this.enrollInfo = args.enrollInfo;
    this.thumbLogo = args.thumbLogo;
    this.roomName = args.roomName;

    this.lecturers = this.hasMany(Lecturers, args.lecturers)

    this.timeOfEvent = `${Parse.timeOfDay(this.starting)} - ${Parse.timeOfDay(this.finishing)}`;
  }
}