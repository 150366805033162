import React, { Component } from 'react';
import CongressProgramWorkshopsEvents from './congress_program_workshops';
import RoomBadge from '../../shared/_room_badge';
import LectureDescription from './lecture_description';

export default class CongressProgramCollapsed extends Component {
  render() {
    const { events, workshops } = this.props;

    return (
      <div className='congress-program-collapsed'>
        {events.length > 0 &&
        <div>
          <h3 className='congress-program-collapsed__title'>
            Wybrane wykłady
          </h3>
          <ul className='congress-program-collapsed__list'>
            {events.map((event, key) =>
              <li className='congress-program-collapsed__item' key={key}>
                <p className='congress-program-collapsed__lecture-title'>
                  {event.timeOfEvent}
                </p>
                <div className='congress-program-collapsed__lecture-title-row'>
                  <p className='congress-program-collapsed__lecture-title'>
                    {event.title}
                  </p>
                  {event.roomName &&
                  <RoomBadge roomName={event.roomName}
                             additionalClassNames='room-badge_small congress-program-collapsed__room-badge'/>
                  }
                </div>
                {event.lecturers.length > 0 && event.lecturers.map((lecturer, index) =>
                  <p className='congress-program-collapsed__lecture-lecturer' key={index}>
                    {lecturer.nameWithDegree}
                  </p>
                )}
                {event.description &&
                <LectureDescription description={event.description}/>
                }
              </li>
            )}
          </ul>
        </div>
        }

        {workshops.length > 0 &&
        <div>
          <h3 className='congress-program-collapsed__title'>
            Wybrane warsztaty
          </h3>
          <ul className='congress-program-collapsed__list'>
            {workshops.map((workshop, key) =>
              <li className='congress-program-collapsed__item congress-program-collapsed__item_no-padding' key={key}>
                <CongressProgramWorkshopsEvents event={workshop}/>
              </li>
            )}
          </ul>
        </div>
        }
      </div>
    )
  }
}