import Api from '../../../api/Api';
import * as PurchaseProcessor from '../../../helpers/purchase_processor';

export class PurchaseProduct {
  constructor(product) {

    this.productId = product.productId !== undefined ? product.productId : product.id;
    this.name = product.name ? product.name : product.title;
    this.basePrice = product.basePrice;
    this.businessPrice = product.businessPrice;
    this.baseDiscountPrice = product.baseDiscountPrice ? product.baseDiscountPrice : product.basePrice;
    this.businessDiscountPrice = product.businessDiscountPrice ? product.businessDiscountPrice : product.businessPrice;
    this.backgroundImage =  product.backgroundImage ? product.backgroundImage : product.mediumPicture ? product.mediumPicture : product.picture;
    this.type = null;
    this.tooltip = product.tooltip;
    this.displayPrice = product.displayPrice

    this.sendToCart = (quantity) => {
      let url = PurchaseProcessor.buildAddToCartUrl(this.productId, quantity);
      return Api.sendProducts(url)
    }

    this.hasDiscount = () => {
      return this.displayPrice !== undefined || (this.baseDiscountPrice !== this.basePrice)
    }

    this.productType = () => {
      if(this.type === 'related') {
        return 'KONGRES'
      }
    }

    this.discountPrice = () => {
      return this.displayPrice || this.baseDiscountPrice
    }
  }
}
