import React from 'react';
import SpecialOfferItem from './special_offer_item';
import TooltipContainer from '../../shared/_tooltip_container';

export default function SpecialOffer(props) {
  const { relatedProducts, relatedInfo } = props;

  let tooltipStyle;
  if (relatedInfo.tooltip) {
    tooltipStyle = {
      'width'           : '15rem',
      'left'            : 'unset',
      'right'           : '-2rem',
      'backgroundColor' : '#000',
      'color'           : '#FFF',
      'padding'         : '1rem',
      'fontSize'        : '.875rem'
    }
  }

  return (
    <div className={`ticket-purchase__row ticket-offer`}>
      <div className="ticket-offer__row ticket-offer__element">
        <div className="ticket-offer__text ticket-offer__text_title">
          {relatedInfo.description}
          {relatedInfo.tooltip &&
          <TooltipContainer
            tooltipContent={relatedInfo.tooltip}
            buttonContent='i'
            buttonType='roundInfoButton'
            tooltipStyle={tooltipStyle}
          />
          }
        </div>
      </div>
      <div className='grid__row'>
        {relatedProducts.map((product, index) =>
          <SpecialOfferItem
            product={product}
            key={index}
            amount={relatedProducts.length}
          />
        )}
      </div>
    </div>
  )
}