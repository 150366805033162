import {Model} from '@itsfadnis/jsonapi-client';
import { PurchaseProduct } from '../data_handlers/PurchaseProduct'

export class ConferenceSectionLiveTransmissions extends Model {
  static _type = 'conference_section_live_transmissions';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.mediumImage = args.mediumImage;
    this.thumbImage = args.thumbImage;
    this.promoInfo = args.promoInfo;
    this.description = args.description;
    this.sectionTitle = args.sectionTitle;
    this.boxTitle = args.boxTitle;
    this.callToAction = args.callToAction;

    this.product = this.hasOne(PurchaseProduct, args.product);
  }
}