import React, {Component} from 'react';
import propTypes from 'prop-types';
import Tooltip from './_tooltip'

export default class TooltipContainer extends Component{
  static propTypes = {
    tooltipContent: propTypes.string.isRequired,
    buttonContent: propTypes.string.isRequired,
    buttonType: propTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.tooltipContainer = React.createRef();

    this.state = {
      isPopupVisible: false
    }
  }

  toggleTooltip = () => {
    this.setState({
      isPopupVisible: !this.state.isPopupVisible
    })
  }

  createButton = (buttonType, buttonContent) => {
    switch(buttonType) {
      case 'roundInfoButton':
        return (
            <button className="button button_sm button-success-reverted ticket-offer__info-button" onClick={this.toggleTooltip}>
              {buttonContent}
            </button>
        )
      case 'successTextButton':
        return (
            <button className='ticket-offer__show-tooltip-button' onClick={this.toggleTooltip}>
              {buttonContent}
            </button>
        )
      default:
        return null;
    }
  };

  _handleWindowClick = (e) => {
    const node = this.tooltipContainer.current;
    if(!node.contains(e.target)) {
      this.toggleTooltip(e);
      window.removeEventListener('click', this._handleWindowClick)
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isPopupVisible !== this.state.isPopupVisible && this.state.isPopupVisible === true) {
      window.addEventListener('click', this._handleWindowClick)
    }
  }

  render() {

    const {
        props: {tooltipContent, buttonContent, buttonType, tooltipStyle},
        state: {isPopupVisible},
        createButton
    } = this;

    return (
        <div className='ticket-offer__show-tooltip-container' ref={this.tooltipContainer}>
          {createButton(buttonType, buttonContent)}
          <Tooltip
            visible={isPopupVisible}
            content={tooltipContent}
            tooltipStyle={tooltipStyle}
          />
        </div>
    )
  }
}