import React from 'react';
import PropTypes from 'prop-types'

export default function Tooltip(props) {
  return (
      <span
        className={`tooltip ${props.visible ? 'tooltip_show' : ''}`}
        style={props.tooltipStyle ? props.tooltipStyle : null}
        dangerouslySetInnerHTML={{__html: props.content}}
      />
  )
}

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired
};