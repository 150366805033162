import React from 'react';
import ReactPlayer from "react-player";

export default function VideoInvitation(props) {
  const { title, videoLinks } = props.videoInvitationData;
  return (
    <div className='video-invitation page__section' id='video_invitations'>
      <h3 className="section-title">
        {title}
      </h3>
      { videoLinks.length > 0 && videoLinks.map(link => (
        <div className='video-invitation__player'>
          <ReactPlayer
            url={link}
            controls
            width='100%'/>
        </div>
      )) }
    </div>
  )
}
