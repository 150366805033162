import React, {Component} from 'react';
import {Parse} from '../../helpers/parser';

export default class HeaderTitleCard extends Component {
  render() {
    const {titleCardData} = this.props;
    const format = 'DD.MM.YYYY';

    const titleDesktopStyle = {
      fontSize: titleCardData.desktopFontSize,
    };

    const titleMobileStyle = {
      fontSize: titleCardData.mobileFontSize,
    };

    const parseDate = Parse.durationDates(titleCardData.startDate, titleCardData.endDate, format);

    return (
      <div className='header__title-card title-card'>
        <div className='title-card__place-n-date title-card__element'>
          {`${titleCardData.city}, ${parseDate}`}
        </div>
        <div className='title-card__congress-number title-card__element'>
          {titleCardData.title}
        </div>
        <h1 className='title-card__specialization title-card__element' style={window.isSmallView ? titleMobileStyle : titleDesktopStyle}>
          {titleCardData.specialization}
        </h1>
        {titleCardData.claim.length > 0 &&
          <h3 className='title-card__claim title-card__element'>
            {titleCardData.claim}
          </h3>
        }
        {titleCardData.isCongressFuture &&
          <div className='buttons-full-row-mobile'>
            <a href="#ticket" className='button button_md button-success'>
              KUP BILET
            </a>
            <a href="#agenda" className='button button_md button-success-reverted'>
              sprawdź program
            </a>
          </div>
        }
        {!titleCardData.isCongressFuture &&
          <div className='buttons-full-row-mobile'>
            <a href="/kongresy" className='button button_md button-success'>
              Sprawdź nadchodzące kongresy
            </a>
          </div>
        }

      </div>
    )
  }
}