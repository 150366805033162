import React, {Component} from 'react';
import {BookIcon} from '../../../assets/icons/icons';

export default class SubscribeLink extends Component {
  render() {
    return (
        <a className='button header-magwet__link menu-link subscribe-link' href='/subskrypcja'>
          <BookIcon className='icon_white icon_margined d_inline-block d_lg_none d_xl_inline-block'/>
          <p>
            Prenumeruj
            <br/>
            <span className='d_inline-block d_lg_none d_xl_inline-block'>
              Odnów subskrypcję
            </span>
          </p>
        </a>
    )
  }
}