import {Model} from '@itsfadnis/jsonapi-client';

export class ConferenceSectionVideoInvitations extends Model {
  static _type = 'conference_section_video_invitations';

  constructor(args={}) {
    super(args)

    this.id = args.id;
    this.title = args.title;
    this.videoLinks = args.videoLinks;
  }
}