import React, {Component} from 'react';
import {ClockIcon, CoffeeIcon, MealIcon, PresentationIcon, ScheduleIcon, TalkingHeadsIcon} from "../../assets/icons/icons";

export default class BasicInfo extends Component {
  render() {
    const {summary} = this.props;
    const isSmallView = window.isSmallView;
    const iconSize = isSmallView ? '40' : '70';

    return (
        <div className='page__section basic-info' id='summary'>
          <div className="grid__container">
            <div className="grid__row">
              <div className="grid__col_12 grid__col_lg_3"/>
              <div className="grid__col_12 grid__col_lg_9">
                <ul className='grid__row'>
                  <li className="basic-info__item basic-info-column grid__col_4 grid__col_md_2">
                    <PresentationIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                    <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                      wykłady:
                    </p>
                    <p className="basic-info-column__text basic-info-column__item">
                      {summary.block.lectures}
                    </p>
                  </li>
                  <li className="basic-info__item basic-info-column grid__col_4 grid__col_md_2">
                    <ClockIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                    <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                      godziny:
                    </p>
                    <p className="basic-info-column__text basic-info-column__item">
                      {summary.block.hours}
                    </p>
                  </li>
                  <li className="basic-info__item basic-info-column grid__col_4 grid__col_md_2">
                    <ScheduleIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                    <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                      dni:
                    </p>
                    <p className="basic-info-column__text basic-info-column__item">
                      {summary.block.days}
                    </p>
                  </li>
                  <li className="basic-info__item basic-info-column  grid__col_4 grid__col_md_2">
                    <TalkingHeadsIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                    <p className="basic-info-column__text basic-info-column__item basic-info-column__text_small">
                      bloki dyskusyjne:
                    </p>
                    <p className="basic-info-column__text basic-info-column__item">
                      {summary.block.discussionBlocks}
                    </p>
                  </li>
                  <li className="basic-info__item basic-info-column grid__col_4 grid__col_md_2">
                    <MealIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                    <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                      przerwy obiadowe:
                    </p>
                    <p className="basic-info-column__text basic-info-column__item">
                      {summary.block.lunches}
                    </p>
                  </li>
                  <li className="basic-info__item basic-info-column grid__col_4 grid__col_md_2">
                    <CoffeeIcon width={iconSize} height={iconSize} className='basic-info__icon basic-info-column__item'/>
                    <p className="basic-info-column__text basic-info-column__text_small basic-info-column__item">
                      przerwy kawowe:
                    </p>
                    <p className="basic-info-column__text basic-info-column__item">
                      {summary.block.breaks}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    )
  }
}