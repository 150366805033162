import React, {Component} from 'react';
import {Calendar, MapMark} from '../../assets/icons/icons';
import {Parse} from '../../helpers/parser';
import moment from 'moment'

export default class CongressListCard extends Component {

  render() {
    const {data} = this.props;
    const {city, starting, finishing, conferenceInfo, sections} = data;
    const {sliders, specialization, title, slug} = conferenceInfo;
    const invitation = sections.filter(section => {return section.classification === 'invitation'});
    const [startDate, endDate] = [moment(starting), moment(finishing)]
    const dateNow = moment();
    const daysLeft = startDate.diff(dateNow, 'days');
    const minutesLeft = startDate.diff(dateNow, 'minutes');
    const hourOfStart = startDate.format('HH:mm');
    const text = daysLeft > 0 ? 'POZOSTAŁO DNI' : minutesLeft > 0 ? 'START DZIŚ' : 'W TRAKCIE';
    const time = daysLeft > 0 ? daysLeft : minutesLeft > 0 ? hourOfStart : '';

    return (
        <article className='congress-card'>
          <a href={`/kongresy/${slug}/${city}`} className='congress-card__link'>
            <div className='congress-card__image-container'>
              <div className="congress-card__badge congress-card-badge">
                {text}
                <span className={`${daysLeft > 0 || minutesLeft > 0 ? 'congress-card-badge__number' : 'congress-card-badge__number_sm'}`}>
                  {time}
                </span>
              </div>
              <img src={sliders[0].mediumImage} alt="Obrazek główny kongresu" className='congress-card__img'/>
            </div>
            <section className='congress-card__section congress-card__section_top'>
              <h4 className='congress-card__title'>
                {specialization}
              </h4>
              <p className='congress-card__text'>
                {title}
              </p>
              {invitation.length > 0 &&
              <p className='congress-card__text'>
                {invitation[0].invitations[0].lecturers.map((lecturer, index) =>
                  <span className='congress-card__text_sm' key={index}>{lecturer.nameWithDegree}</span>
                )}
              </p>
              }
            </section>
            <div className="congress-card__line"/>
            <section className='congress-card__section congress-card__section_bottom'>
              <div className="congress-card__date-n-place">
                <div className="congress-card__date">
                  <Calendar width='18px' height='18px' className='icon_grey'/>
                  <span className='congress-card__info'>{Parse.durationDates(startDate, endDate, 'DD.MM.YYYY')}</span>
                </div>
                <div className="congress-card__place">
                  <MapMark width='18px' height='18px' className='icon_grey'/>
                  <span className='congress-card__info'>{city}</span>
                </div>
              </div>
              <div className='congress-card__row-button'>
                <button className='link button button-success button_md congress-card__button'>Sprawdź szczegóły</button>
              </div>
            </section>
          </a>
        </article>
    )
  }
}