import React, {Component} from 'react';
import {PageHelper} from '../../helpers/page_helper';
import {FacebookIcon} from '../../assets/icons/icons';
import Switcher from './switcher';
import MarketingAlertMenu from './marketing_alerts/marketing_alert_menu';
import HTMLContentHelper from '../../helpers/html_content_helper';
import MenuLinkItem from './menu_link_item';

export default class MenuFixed extends Component {
  constructor(props) {
    super(props);
    this._extendMenuOnScroll = this._extendMenuOnScroll.bind(this);
    this.state = {
      fixed: false,
      menuExtended: false,
    };
  }

  _extendMenuOnScroll = () => {
    const header = document.querySelector('.header-magwet');
    const isBelowMenu = PageHelper.isWindowBelowMenu(header);
    if(this.state.menuExtended !== isBelowMenu) {
      this.setState({menuExtended: isBelowMenu})
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this._extendMenuOnScroll)
  }

  render() {
    const {conference, changeCurrentCityId, isCongressFuture, currentPlace, currentCityId} = this.props;

    const {visibleSortedMenuSections, parsedDate, sponsorsLogo} = currentPlace;

    const {publishedCities} = conference;

    const titleFontSize = conference.specialization.length > 25 ?  {fontSize: conference.specializationFontSize/1.75 + 'px'} : {fontSize: conference.specializationFontSize/1.25 + 'px'};

    return (
        <div className={`menu grid__padding-vertical_top ${this.state.menuExtended ? 'menu_top-value' : ''}`}>
          <div className={`menu__header ${this.state.menuExtended ? 'menu__header_show' : ''}`} >
            <h2 className='menu__title menu__item' style={titleFontSize}>
              {conference.specialization}
            </h2>

            {publishedCities.length > 1 &&
              <Switcher context='menu'
                        currentCityId={currentCityId}
                        conferencePlaces={publishedCities}
                        changeCurrentCityId={changeCurrentCityId}
                        currentPlace={currentPlace}
              />
            }

            {publishedCities.length <= 1 &&
              <div className='menu__place-n-date menu__item'>
                {`${currentPlace.city}, ${parsedDate}`}
              </div>
            }

            {isCongressFuture &&
              <a href='#ticket' className='button button_sm button-success menu__item'>
                Kup bilet
              </a>
            }

          </div>
          <nav className='menu__nav'>
            <ul className='menu__list'>
              <MenuLinkItem url='#header' content='Kongres'/>

              {visibleSortedMenuSections.length > 0 && visibleSortedMenuSections.map((section, index) =>
                  <MenuLinkItem
                      url={`#${section.classification}`}
                      content={section.titleMenu}
                      className={`${section.classification === 'ticket' ? 'link_success link_bold' : ''}`}
                      key={index}
                  />
              )}

              <MenuLinkItem content='Organizator' url='#promoter'/>
              <MenuLinkItem content='Kontakt' url='#contact'/>
            </ul>
          </nav>

          {sponsorsLogo &&
            <div className={`menu__footer ${this.state.menuExtended ? 'menu__footer_show' : ''}`}>
              <div className='menu__founder menu__item'>
                <p>Sponsor:</p>
                <img src={`${sponsorsLogo.thumbImage}`} alt={`Logo sponsora`} className='menu__founder-img'/>
              </div>
            </div>
          }

          <div className='menu__social menu__item'>

            {currentPlace.socialLinks.facebook !== '' &&
              <a href={currentPlace.socialLinks.facebook} className='menu__social_link' target='_blank' rel="noopener noreferrer">
                <FacebookIcon iconTitle='FacebookIcon' width='27' height='27' className='menu__social_icon'/>
              </a>
            }
            {/*<a href="/" className='menu__social_link'>
              <ShareIcon iconTitle='ShareIcon' width='27' height='27' className='menu__social_icon'/>
            </a>*/}

          </div>

          {HTMLContentHelper.hasHtmlStringInnerText(currentPlace.marketingAlertMenu) &&
            <div>
              <MarketingAlertMenu data={currentPlace.marketingAlertMenu} popupData={currentPlace.marketingAlertPopup}/>
            </div>
          }

        </div>
    )
  }
}