import React, {Component} from 'react';
import DetailsForCity from './details_for_city';
import Invitation from './invitation';
import CongressProgram from "./program/congress_program";
import BasicInfo from "./summary";
import Lecturers from "./lecturers";
import Tickets from './tickets';
import LiveTransmission from './live_transmission';
import PreviousCongresses from './previous_congresses';
import CongressSponsors from './congress_sponsors';
import CongressExhibitors from './congress_exhibitors';
import CongressPromoter from './congress_promoter';
import CongressPracticalInfo from './congress_practical_info';
import ContactSection from './contact';
import {CardHorizontalWide} from './cards_horizontal';
import {Patronage} from './patronage';
import { Scroll } from '../../helpers/scroll';
import VideoInvitation from "./video_invitation";

export default class Main extends Component {

  componentDidMount() {
    Scroll.toLocationHashId();
  }

  render() {
    const {conference, currentCityId, changeCurrentCityId, rules, checkUserAttributes, isCongressFuture, currentPlace} = this.props;
    const {apiSections} = currentPlace;

    return (
        <div className='page'>
          <div className="grid__container">
            <div className="grid__row">
              <div className="grid__col_12 grid__col_lg_3"/>
              <div className="grid__col_12 grid__col_lg_9">

                {conference.publishedCities.length > 1 &&
                  <DetailsForCity conferencePlaces={conference.publishedCities}
                                  currentCityId={currentCityId}
                                  changeCurrentCityId={changeCurrentCityId}
                                  currentPlace={currentPlace}
                  />
                }

                {apiSections.patronages &&
                    <Patronage data={apiSections.patronages}/>
                }

                {apiSections.invitations &&
                    <Invitation invitationData={apiSections.invitations.invitations[0]}/>
                }

                {apiSections.videoInvitations &&
                    <VideoInvitation videoInvitationData={apiSections.videoInvitations.videoInvitations[0]}/>
                }

                {apiSections.program &&
                    <CongressProgram
                        program={apiSections.program.agendas[0]}
                        currentPlace={currentPlace}
                    />
                }

              </div>
            </div>
          </div>

          {apiSections.summary &&
              <BasicInfo summary={apiSections.summary.summaries[0]}/>
          }

          <div className="grid__container">
            <div className="grid__row">
              <div className="grid__col_12 grid__col_lg_3"/>
              <div className="grid__col_12 grid__col_lg_9">

                {apiSections.lecturer &&
                    <Lecturers lecturersData={apiSections.lecturer}/>
                }

              </div>
            </div>
          </div>

          {isCongressFuture && apiSections.tickets &&
              <Tickets ticket={apiSections.tickets} currentPlace={currentPlace} checkUserAttributes={checkUserAttributes}/>
          }

          <div className="grid__container">
            <div className="grid__row">
              <div className="grid__col_12 grid__col_lg_3"/>
              <div className="grid__col_12 grid__col_lg_9">

                {apiSections.liveTransmission &&
                    <section className='page__section' >
                      <LiveTransmission liveTransmission={apiSections.liveTransmission}/>
                    </section>
                }

                {(apiSections.additional || apiSections.advantages) &&
                    <div>
                      <h4 className='section-title section-title_small'>
                        Dodatkowo
                      </h4>
                      <section className='page__section'>

                        {apiSections.additional &&
                            <CardHorizontalWide content={apiSections.additional.entertainmentEvenings[0]} framed={true}/>
                        }

                      </section>
                      <section className='page__section'>

                        {apiSections.advantages &&
                            <CardHorizontalWide content={apiSections.advantages.packageInfos[0]} framed={false} type='list'/>
                        }

                      </section>
                  </div>
                }

                {apiSections.previousEdition &&
                    <PreviousCongresses data={apiSections.previousEdition}/>
                }

                {apiSections.sponsors &&
                    <CongressSponsors data={apiSections.sponsors}/>
                }

                {apiSections.exhibitors &&
                    <CongressExhibitors data={apiSections.exhibitors}/>
                }

                <CongressPromoter/>

                {apiSections.practicalInfo &&
                  <CongressPracticalInfo practicalInfo={apiSections.practicalInfo} rules={rules}/>
                }

                <ContactSection conferenceId={conference.id}/>
              </div>
            </div>
          </div>
        </div>
    )
  }
}