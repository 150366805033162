import {HttpAdapter, Model} from '@itsfadnis/jsonapi-client';
import {base_url} from '../../../api/Api';
import {Conference} from '../../congress_single/entity/Conference';
import {Section} from '../../congress_single/entity/Section';
import moment from 'moment';

Model.adapter = new HttpAdapter({
  host: window.location.port === '' ? window.location.origin : base_url,
  namespace: '/api/v1',
  mode: 'cors',
  fetch: window.fetch.bind(window)
});

export class CongressListModel extends Model {
  static _type = 'conferenceInfos';
  static baseURL = '/conference_places';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.city = args.city;
    this.starting = args.starting;
    this.finishing = args.finishing;
    this.educationalPoints = args.educationalPoints;
    this.position = args.position;
    this.socialLinks = args.socialLinks;
    this.freeTicketsToSell = args.freeTicketsToSell;
    this.publishedAt= args.publishedAt;
    this.sections = this.hasMany(Section, args.sections);
    this.conferenceInfo = this.belongsTo(Conference, args.conferenceInfo)

    this.dateNow = moment();
    this.dateOfStart = moment(this.starting);
    this.dateOfEnd = moment(this.finishing);
    this.isFuture = this.dateOfEnd.diff(this.dateNow, 'hours') > 0;
    this.isPast = this.dateOfEnd.diff(this.dateNow, 'days') < 0;
  }
}