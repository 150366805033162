import {HttpAdapter, Model} from '@itsfadnis/jsonapi-client';
import {ConferencePlace} from './ConferencePlace';
import {Slider} from './Slider';
import {base_url} from '../../../api/Api';
import { Sort } from '../../../helpers/sort';


Model.adapter = new HttpAdapter({
  host: window.location.port === '' ? window.location.origin : base_url,
  namespace: '/api/v1',
  mode: 'cors',
  fetch: window.fetch.bind(window)
});

export class Conference extends Model {
  static _type = 'conferenceInfos';
  static baseURL = '/conference_infos';

  constructor(args = {}) {
    super(args);
    this.id = args.id;
    this.title = args.title;
    this.specialization = args.specialization;
    this.claim = args.claim;
    this.specializationFontSize = args.specializationFontSize;
    this.specializationMobileFontSize = args.specializationMobileFontSize;
    this.regulamin = args.regulamin;
    this.onSiteTerms = args.onSiteTerms;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.metaTags = args.metaTags;
    this.slug = args.slug;
    this.conferencePlaces = this.hasMany(ConferencePlace, args.conferencePlaces);
    this.sliders = this.hasMany(Slider, args.sliders);
    this.onSiteTerms = args.onSiteTerms;

    this.publishedCities = Sort.by_position(this.conferencePlaces).filter(place => place.publishedAt !== null);
    this.metaData = {
      title: this.title,
      specialization: this.specialization,
      seoTitle: this.metaTags && this.metaTags.seoTitle !== null && this.metaTags.seoTitle !== '' ? this.metaTags.seoTitle : 'Kongres magwet.pl',
      seoDescription: this.metaTags && this.metaTags.seoDescription !== null && this.metaTags.seoDescription !== '' ? this.metaTags.seoDescription : "Kongres Akademii po Dyplomie",
      firstSlider: this.sliders.length > 0 ? this.sliders[0].mediumImage : '',
      secondSlider: this.sliders.length > 1 ? this.sliders[1].mediumImage : '',
      firstSliderThumb: this.sliders.length > 0 ? this.sliders[0].thumbImage : '',
      base_url: base_url,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }
}

