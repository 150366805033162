import {Model} from '@itsfadnis/jsonapi-client';
import {ConferenceSectionAgendaSectionEvents} from './ConferenceSectionAgendaSectionEvents';
import {Sort} from '../../../helpers/sort';

export class ConferenceSectionWorkshopSessions extends Model {
  static _type = 'conference_section_workshop_sessions';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.title = args.title;
    this.date = args.date;
    this.description = args.description;
    this.lft = args.lft;
    this.rgt = args.rgt;

    this.events = this.hasMany(ConferenceSectionAgendaSectionEvents, args.events);
    this.eventsByTime = this.sort_by_starting_time(this.events);
    this.starting = this.eventsByTime[0];
  }

  sort_by_starting_time = (events) => {
    return Sort.by_starting_time(events)
  }
}