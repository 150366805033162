import {Model} from '@itsfadnis/jsonapi-client';
import {Slider} from './Slider';
import {ConferenceSectionTicketPool} from './ConferenceSectionTicketPool';
import {PurchaseProduct} from '../data_handlers/PurchaseProduct';
import moment from 'moment';

export class ConferenceSectionTickets extends Model {
  static _type = 'conference_section_tickets';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.allowMultipleWorkshops = args.allowMultipleWorkshops;
    this.ticketPools = this.hasMany(ConferenceSectionTicketPool, args.ticketPools);
    this.currentPool = this.findCurrentPool(this.ticketPools);
    this.backgroundImage = !!args.backgroundImage ? this.hasOne(Slider, args.backgroundImage) : null;

    this.mainProduct = !!this.currentPool ?  new PurchaseProduct(this.currentPool.ticketProduct) : null;
    this.workshopProducts = !!args.workshopProducts ? this.hasMany(PurchaseProduct, args.workshopProducts) : null;
    this.conferenceRelatedProducts = !!args.conferenceRelatedProducts ? this.hasMany(PurchaseProduct, args.conferenceRelatedProducts) : null;
    this.entertainmentProduct = !!args.entertainmentProduct ? this.hasOne(PurchaseProduct, args.entertainmentProduct) : null;
    this.relatedProducts = !!args.relatedProducts ? this.hasMany(PurchaseProduct, args.relatedProducts) : null;

    this.entertainmentProductHeader = args.entertainmentProductHeader;

    this.setTypeAttributes();

    this.specialOfferInfo = {
      'tooltip':      args.specialOfferTooltip,
      'description':  args.specialOfferDescription
    };

    this.specialOfferProductInfo = {
      'tooltip':      args.specialOfferProductTooltip,
      'description':  args.specialOfferProductDescription
    };
  }

  setTypeAttributes = () => {
    !!this.mainProduct && this.setProductType(this.mainProduct, 'main');
    !!this.workshopProducts && this.workshopProducts.forEach(p => this.setProductType(p, 'workshop'));
    !!this.conferenceRelatedProducts && this.conferenceRelatedProducts.forEach(p => this.setProductType(p, 'product'));
    !!this.entertainmentProduct && this.setProductType(this.entertainmentProduct, 'entertainment');
    !!this.relatedProducts && this.relatedProducts.forEach(p => this.setProductType(p, 'related'));
  };

  setProductType = (product, type) => {
    product.type = type
  };

  findCurrentPool = (pools) => {
    return pools.find((pool) => {
      let dateNow = moment();
      return dateNow.isBetween(pool.ticketProduct.campaignStartAt, pool.ticketProduct.campaignEndAt);
    })
  }
}
