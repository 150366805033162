import React, {Component} from 'react';
import AddToCartForm from './add_to_cart_form';
import { base_url } from '../../../api/Api';

export default class SpecialOfferItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productSent: false
    }
  }

  handleSubmit = () => {
    return this.props.product.sendToCart(1)
      .then(() => this.setState({ productSent: true }))
      .catch(e => console.error(e))
  };

  render() {
    const {product, amount} = this.props;
    const { productSent } = this.state;
    const background = { 'backgroundImage': `url(${base_url}${product.backgroundImage})` };

    return (
      <>
        <div className={`special-offer-item grid__col_6 grid__col_sm_6`}>
          <div className="special-offer-item__image" style={background}/>
          <div>
            <p className="special-offer-item__type">
              KONGRES
            </p>
            <p className="special-offer-item__name">
              {product.name}
            </p>
            <p className="special-offer-item__prices">
              <span className="special-offer-item__price">
                {Number(product.displayPrice)}&nbsp;zł
              </span>
              <del className="special-offer-item__old-price">
                {product.basePrice}&nbsp;zł
              </del>
            </p>
          </div>
        </div>
        <div className="special-offer-item-add-to-cart grid__col_6 grid__col_sm_6">
          <AddToCartForm handleSubmit={this.handleSubmit} productsSent={productSent}/>
        </div>
      </>
    )
  }
}
