import {Component} from 'react';
import React from 'react';
import {base_url} from '../../../api/Api';
import MenuBadge from '../_menu_badge';
import {Arrow, MagwetEventIcon} from '../../../assets/icons/icons';

export default class MenuElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
    }
  }

  _openDropdown = (e) => {
    e.preventDefault();
    this.setState({
      isDropdownOpen: true
    })
  };

  _closeDropdown = (e) => {
    e.preventDefault();
    this.setState({
      isDropdownOpen: false
    })
  };

  _toggleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    })
  };

  render() {
    const { menuItem } = this.props

    let isDropdown = menuItem.subMenus.length > 0;
    let dropdownItems = menuItem.subMenus;
    let visible = this.state.isDropdownOpen ? 'dropdown_show' : '';

    return (
        <li className='header-magwet__item header-magwet__item_relative mobile-menu__item'
            ref={this.dropdown}
            onMouseEnter={isDropdown ? this._openDropdown : null}
            onMouseLeave={isDropdown ? this._closeDropdown : null}
            onTouchStart={window.isSmallView ? this._toggleDropdown : null}
            onTouchEnd={e => e.preventDefault()}
        >

          <a href={`${base_url}${menuItem.url === null ? '/' : menuItem.url}`}
             className={`header-magwet__link menu-link mobile-menu__link ${isDropdown ? 'menu-link__dropdown' : ''} ${this.state.isDropdownOpen ? 'menu-link__dropdown_open' : ''}`}>

            <span className='d_inline-block d_lg_none'>
              <MagwetEventIcon width='30' height='30' className='icon icon_white icon_margined'/>
            </span>

            <span className='menu-link__text' dangerouslySetInnerHTML={{__html: menuItem.title}}/>

            {isDropdown &&
              <Arrow className={`icon_arrow icon_arrow_down ${(this.state.isDropdownOpen && !window.isSmallView) ? 'icon_arrow_green' : 'icon_arrow_white'}`} width='20px' height='100%'/>
            }
          </a>

          {isDropdown &&
            <div className={`dropdown ${visible}`}>
              {dropdownItems && dropdownItems.map((item, index) =>
                  <DropdownItem item={item} key={index}/>
              )}
            </div>
          }
        </li>
    )
  }
}

function DropdownItem(props) {
  const {item} = props;
  
  let menuLink = (menuLink) => { 
    return menuLink.startsWith('/') ? `${base_url}${menuLink}` : menuLink; 
  }
  
  return (
      <a href={menuLink(item.url)} className='dropdown__item'>
        {item.title}
        {item.badgeType.length > 0 &&
          <MenuBadge badge_type={item.badgeType[0]}/>
        }
      </a>
  )
}
