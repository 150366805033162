export default {
  2019: [
    {
      name: 'Weterynaria 2019 "KLINICZNE ASPEKTY PRAKTYKI LEKARSKO-WETERYNARYJNEJ"',
      url: '/weterynaria-2019 '
    },
  ],
  2018: [
    {
      name: 'Weterynaria 2018 "PUŁAPKI DIAGNOSTYCZNE I TERAPEUTYCZNE"',
      url: '/weterynaria-2018'
    },
  ],

  2017: [
    {
      name: 'Weterynaria 2017 "CODZIENNA PRAKTYKA – ALGORYTMY POSTĘPOWANIA"',
      url: '/apd_weterynaria_2017'
    },
  ]
}

