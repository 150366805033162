import React, {Component} from 'react';
import HeaderPodyplomie from '../shared/_header_magwet';
import FooterPodyplomie from '../shared/_footer_magwet';
import {CongressListModel} from '../../models/congress_list/entity/CongressListModel';
import CongressListCard from './congress_list_card';
import moment from 'moment';
import {Sort} from '../../helpers/sort';
import _ from 'lodash';
import {PdfColorIcon} from '../../assets/icons/icons';
import Api, {base_url} from '../../api/Api';
import archiveData from '../../assets/contentData/archive_data'
import SeoTags from '../shared/_seo_tags';

export default class CongressList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      isLoading: true,
      isLoggedIn: false,
      productsInCart: 0,
      showArchive: false
    }
  }

  _futureCongresses = (arrayOfEvents) => {
    return arrayOfEvents.filter(event => event.isFuture)
  };

  _pastCongresses = (arrayOfEvents) => {
    return arrayOfEvents.filter(event => event.isPast)
  };

  _sortEventsByYears = (events) => {
    const date = item => moment(item.starting).year();
    const sortedEvents = Sort.by_startDate(events);
    return _.groupBy(sortedEvents, date)
  };


  _checkUserAttributes = () => {
    Api
        .getUserHeaderInfo()
        .then(response => response.json())
        .then(data =>
            this.setState({
              isLoggedIn: data.api_token !== null,
              productsInCart: data.products_in_cart
            })
        )
  };

  _toggleArchive = () => {
    this.setState({
      showArchive: !this.state.showArchive
    })
  };

  _mergeArchiveLinksWithNewData = (newObject, oldObject) => {
    let resultObject = {}

    for (let oldObjectKey of Object.keys(oldObject)) {
      resultObject[oldObjectKey] = oldObject[oldObjectKey]
    }

    for (let newObjectKey of Object.keys(newObject)) {
      for (let resultObjectKey of Object.keys(resultObject)) {
        if (resultObjectKey === newObjectKey) {
          resultObject[resultObjectKey] = [...newObject[newObjectKey], ...resultObject[resultObjectKey]]
        } else if (resultObject[newObjectKey] === undefined) {
          resultObject[newObjectKey] = newObject[newObjectKey]
        }
      }
    }

    return resultObject
  };

  componentDidMount() {
    CongressListModel.fetch(`?include=conferenceInfo,conferenceInfo.sliders,sections,sections.invitations,sections.invitations.lecturers`)
        .then(data => {
          this.setState({response: data})
        }).then(() => {
      this.setState({
        isLoading: false
      })
    });

    this._checkUserAttributes()
  }

  render() {
    if (!this.state.isLoading) {

      const congresses = this.state.response;

      const futureCongresses = this._futureCongresses(congresses);
      const pastCongresses = this._pastCongresses(congresses);

      const congressesByYears = this._sortEventsByYears(futureCongresses);
      const pastCongressesByYears = this._sortEventsByYears(pastCongresses);

      const keysOfCongresses = Object.keys(congressesByYears);
      const rules = congresses[0].conferenceInfo.regulamin;
      const on_site_rules = congresses[0].conferenceInfo.onSiteTerms;

      const finalArchiveObject = this._mergeArchiveLinksWithNewData(pastCongressesByYears, archiveData)
      const keysOfFinalArchiveObject = Object.keys(finalArchiveObject).reverse()

      const metaData = {
        title: 'Kongresy Akademii po Dyplomie',
        description: 'Odkryj nadchodzące Kongresy Akademii po Dyplomie',
        base_url: base_url + '/kongresy'
      }

      return (
          <div>
            <SeoTags metaData={metaData} type='list'/>
            <HeaderPodyplomie isLoggedIn={this.state.isLoggedIn} productsInCart={this.state.productsInCart}/>
            <section className='grid__container'>

              {keysOfCongresses.length > 0 && keysOfCongresses.map((year, index) =>
                  <div className='congress-list' key={index}>
                    <h3 className='congress-list__title'>Kalendarium kongresów Akademii po Dyplomie {year}</h3>
                    <div className='grid__row'>
                      {congressesByYears[year].length < 2 &&
                        <div className='grid__col_md_1 grid__col_lg_2'/>
                      }
                      {congressesByYears[year].length > 0 && congressesByYears[year].map((congress, index) => {
                            if (congress.publishedAt !== null) {
                              return (
                                  <div className={`grid__col_12 ${congressesByYears[year].length < 2 ? 'grid__col_md_10 grid__col_lg_8' : 'grid__col_md_6'}`} key={index}>
                                    <CongressListCard data={congress}/>
                                  </div>
                              )
                            }
                            return null;
                          }
                      )}
                      {congressesByYears[year].length < 2 &&
                      <div className='grid__col_md_1 grid__col_lg_2'/>
                      }
                    </div>
                  </div>
              )}

              <div className='congress-list__rules'>
                <a href={rules} className='congress-list__rules-link' target='_blank' rel='noopener noreferrer'>
                  <PdfColorIcon width='40px' height='40px' className='icon_pdf-color'/>
                  Regulamin kongresów online Akademii po Dyplomie
                </a>
              </div>

              { on_site_rules &&
                <div className='congress-list__rules'>
                  <a href={on_site_rules} className='congress-list__rules-link' target='_blank' rel='noopener noreferrer'>
                    <PdfColorIcon width='40px' height='40px' className='icon_pdf-color'/>
                    Regulamin kongresów stacjonarnych Akademii po Dyplomie
                  </a>
                </div>
	            }

              <div className="congress-list-archive">
                <button className='button congress-list-archive__button' onClick={this._toggleArchive}>
                  Archiwum
                  <span
                      className={`arrow arrow-md arrow-md_black ${this.state.showArchive ? 'arrow-md_up' : 'arrow-md_down'}`}/>
                </button>
                <div
                    className={`grid__row congress-list-archive__row ${this.state.showArchive ? 'congress-list-archive__row_show' : ''}`}>
                  {keysOfFinalArchiveObject.length > 0 && keysOfFinalArchiveObject.map((year, index) =>
                      <div className='grid__col_12 grid__col_md_4' key={index}>
                        {finalArchiveObject[year].length > 0 &&
                        <div className='congress-list-archive-card'>
                          <h4 className='congress-list-archive-card__title'>
                            {year}
                          </h4>
                          {finalArchiveObject[year].map((congress, index) =>
                              <ListPastCongresses data={congress} key={index}/>
                          )}
                        </div>
                        }
                      </div>
                  )}

                </div>
              </div>
            </section>
            <FooterPodyplomie/>
          </div>
      )
    } else {
      return (
          <div className="bouncing-loader">
            <div/>
            <div/>
            <div/>
          </div>
      )
    }
  }
}

function ListPastCongresses(props) {
  const {data} = props;
  const type = data.url ? 'old' : 'new';

  if (type === 'new') {
    return (
        <a href={`/kongresy/${data.conferenceInfo.slug}/${data.city ? data.city.toLowerCase() : ''}`}
           className='congress-list-archive-card__link'>{data.conferenceInfo.specialization}</a>
    )
  } else {
    return (
        <a href={data.url} className='congress-list-archive-card__link'>{data.name}</a>
    )
  }

}
