import React, {Component} from 'react';
import Popup from './popups/popup';
import {base_url} from '../../api/Api';

export class CardHorizontalNarrow extends Component {
  render() {
    const {content, framed, type} = this.props;
    const imageSource = content.mediumImage;
    return (
        <div className={`cards__item card-container ${framed ? '' : 'card-container_no-frame'}`}>
          <div className="card">
            <div className="grid__container">
              <div className="grid__row">
                <div className="grid__col_12 grid__col_sm_8">
                  <div className="card__image-container">
                    <img src={imageSource} alt="" className='card__image'/>
                    {content.promoInfo !== null &&
                      <p className='card__promo-info'>
                        {content.promoInfo}
                      </p>
                    }
                  </div>
                </div>
                <div className="grid__col_12 grid__col_sm_4">
                  <div className="card__text">
                    <RenderProperCardContent content={content} type={type}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export class CardHorizontalWide extends Component {
  render() {
    const {content, framed, type} = this.props;
    const imageSource = content.mediumImage;

    return (
        <div className={`cards__item card-container ${framed ? '' : 'card-container_no-frame'}`} id={`${type === 'list' ? 'package_info' : 'entertainment_evening'}`}>
          <div className="card">
            <div className="grid__container">
              <div className="grid__row">
                <div className="grid__col_12 grid__col_sm_4">
                  <div className="card__image-container">
                    <img src={imageSource} alt="" className='card__image'/>
                  </div>
                </div>
                <div className="grid__col_12 grid__col_sm_8">
                  <div className="card__text">
                    <RenderProperCardContent content={content} type={type}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

function RenderProperCardContent (props) {
  switch (props.type) {
    case 'stream':
      return <CardLiveStreaming content={props.content}/>;
    case 'list':
      return <CardListContent content={ props.content } />;
    default:
      return <CardTextContent content={props.content}/>;
  }
}

class CardLiveStreaming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupVisible: false,
    }
  }

  _togglePopup = () => {
    this.setState({
      isPopupVisible: !this.state.isPopupVisible
    })
  };

  render() {
    const {content} = this.props;
    const parsedPrice = Number(content.product.baseDiscountPrice)

    return (
        <div className='card-stream'>
          <p className='card-stream__title card-stream__item'>
            {content.boxTitle}
          </p>
          <button className='button card-stream__link card-stream__item link link_success' onClick={this._togglePopup}>
            Dowiedz się więcej
          </button>
          <p className='card-stream__price card-stream__item'>
            {parsedPrice} zł
          </p>
          {this.state.isPopupVisible &&
            <Popup data={content} type='stream' popupShow={this.state.isPopupVisible} closePopup={this._togglePopup}/>
          }
          <a href={`${base_url}/products/${content.product.productId}/add_to_cart_from_outside?no_redirect=1`} className='button button_sm button-success card-stream__item card-stream__button' target='_blank' rel='noopener noreferrer'>
            {content.callToAction}
          </a>
        </div>
    )
  }

}

function CardTextContent (props) {
  const content = props.content;
  return (
      <div className='card-text'>
        <h5 className='card-text__title'>
          {content.title}
        </h5>
        <p className='card-text__paragraph' dangerouslySetInnerHTML={{__html: content.htmlContent}}/>
      </div>
  )
}

function CardListContent (props) {
  const content = props.content;

  return(
      <div className='card-list'>
        <h5 className='card-list__title'>
          {content.title}
        </h5>
        <ul className='card-list__list grid__row'>
          {content.bulletPoints.length > 0 && content.bulletPoints.map((listItem, index) =>
              <li className='grid__col_12' key={index}>
                <div className='card-list__item'><span className='card-list__tick'/>{listItem}</div>
              </li>
          )}
        </ul>
      </div>
  )
}