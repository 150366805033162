import React, { Component } from "react";
import moment from 'moment';
import PurchaseAmountWrapper from './ticket_purchase/purchase_amount_wrapper';

export default class SingleTicket extends Component {
  constructor(props) {
    super(props);
    this.maxProductsQuantity = 10;
    this.state = {
      quantity: 1
    }
  }

  openSummary = () => {
    this.props.openSummary();
  };

  increaseQuantity = () => {
    if (this.state.quantity < this.maxProductsQuantity) {
      this.setState({ quantity: this.state.quantity + 1 })
    }
  };

  decreaseQuantity = () => {
    if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 })
    }
  };

  onQuantityChange = (e) => {
    this.setState({ quantity: e.target.value })
  };

  fbAddToCart = (product) => {
    window.fbq('track', 'AddToCart', {
      value: product.basePrice,
      currency : 'PLN',
      content_type: product.productType,
      content_ids: [product.productId],
      content_name: product.name
    });
  }

  gtagAddToCart = (product) => {
    window.gtag("event", "add_to_cart", { 
      currency: "PLN", 
      value: product.baseDiscountPrice, 
      items: [ 
        { 
          item_id: product.productId, 
          item_name: product.campaignName, 
          item_brand: "Magwet", 
          item_category: "Kongresy",  
          price: product.baseDiscountPrice, 
          quantity: 1 
        } 
      ] 
    });
  }

  _handleAddToCart = (e) => {
    e.preventDefault();
    const product = this.props.pool.ticketProduct;
    product.sendToCart(this.state.quantity);

    this.gtagAddToCart(product);
    this.fbAddToCart(product);
    this.props.openSummary();
  };

  render() {
    const { index, pool, background, amount } = this.props;

    const isMobileView = window.isMobileView;
    const getCongressStart = moment(this.props.currentPlace.starting);
    const isSaleDuringCongress = pool.dateOfBeginning.format('DD.MM') === getCongressStart.format('DD.MM');
    const ticketColumnClass = amount === 1 ? 'grid__col_md_8' : amount === 2 ? 'grid__col_md_6' : 'grid__col_md_4';
    const poolSaleInfo = isSaleDuringCongress ? 'W dniu kongresu' : pool.isFuture ? `Od: ${pool.getStartDate}` : `Do: ${pool.getExpFullDate}`;
    const { quantity } = this.state;

    return (
      <div className={`grid__col_12 ${ticketColumnClass}`}>
        <div
          className={`ticket ${pool.isActive ? 'ticket_active' : 'ticket_inactive'}`}
          style={{ backgroundImage: (isMobileView && pool.isActive) ? 'url(' + background + ')' : 'none' }}>
          <div className='ticket__text_space-row'>

            {amount > 1 &&
            <p className={`ticket__text ${pool.isActive ? '' : 'ticket__text_inactive'}`}>
              {index + 1} PULA
            </p>
            }

            <p className={`ticket__text ticket__text_sm ${pool.isActive ? '' : 'ticket__text_inactive'}`}>
              {poolSaleInfo}
            </p>
          </div>
          <div className='ticket__main-info'>
            <div className={`ticket__prices ${pool.isActive ? '' : 'ticket__prices_inactive'}`}>
              <p className='ticket__text ticket__base-price'>
                {pool.baseDiscountPrice} zł
              </p>
            </div>

            {pool.isActive &&
            <PurchaseAmountWrapper
              quantity={quantity}
              increaseQuantity={this.increaseQuantity}
              decreaseQuantity={this.decreaseQuantity}
              onQuantityChange={this.onQuantityChange}
            />
            }

            <div className='ticket__call-to-action'>
              <form action="/" onSubmit={this._handleAddToCart}>
                <button
                  type='submit'
                  className={`ticket__element button ${pool.isActive ? 'button-success' : 'button-disabled'}`}
                >
                  {pool.isActive ? 'Kup bilet' : pool.isFuture ? `Sprzedaż od ${pool.getStartDate}` : 'Wyprzedane'}
                </button>
              </form>

              {pool.isActive &&
              <p className='ticket__element ticket__sticker'>
                {/*Change the declination of words due to number*/}
                {pool.parseTimeLeft}!
              </p>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
