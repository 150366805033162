import {Model} from '@itsfadnis/jsonapi-client';
import {Section} from './Section';
import moment from 'moment';
import { Sort } from '../../../helpers/sort';
import { Parse } from '../../../helpers/parser';

export class ConferencePlace extends Model {
  static _type = 'conferencePlaces';

  constructor(args={}) {
    super(args);

    this.id = args.id;
    this.city = args.city;
    this.starting = args.starting;
    this.finishing = args.finishing;
    this.educationalPoints = args.educationalPoints;
    this.position = args.position;
    this.socialLinks = args.socialLinks;
    this.freeTicketsToSell = args.freeTicketsToSell;
    this.marketingAlertMenu = args.marketingAlertMenu;
    this.marketingAlertHeader = args.marketingAlertHeader;
    this.marketingAlertTicket = args.marketingAlertTicket;
    this.marketingAlertPopup = args.marketingAlertPopup;
    this.publishedAt = args.publishedAt;
    this.roomNames = args.roomNames

    this.sections = this.hasMany(Section, args.sections);

    this.isCongressFuture = moment(this.finishing).diff(moment(), 'hours') > 0;

    this.apiSections = {
      invitations:      this.sections.find(section => {return section.classification === 'invitation'}),
      videoInvitations: this.sections.find(section => {return section.classification === 'video_invitations'}),
      program:          this.sections.find(section => {return section.classification === 'agenda'}),
      summary:          this.sections.find(section => {return section.classification === 'summary'}),
      lecturer:         this.sections.find(section => {return section.classification === 'lecturer'}),
      practicalInfo:    this.sections.find(section => {return section.classification === 'practical_information'}),
      tickets:          this.sections.find(section => {return section.classification === 'ticket'}),
      liveTransmission: this.sections.find(section => {return section.classification === 'live_transmission'}),
      additional:       this.sections.find(section => {return section.classification === 'entertainment_evening'}),
      advantages:       this.sections.find(section => {return section.classification === 'package_info'}),
      previousEdition:  this.sections.find(section => {return section.classification === 'previous_edition'}),
      sponsors:         this.sections.find(section => {return section.classification === 'sponsor'}),
      exhibitors:       this.sections.find(section => {return section.classification === 'exhibitor'}),
      patronages:       this.sections.find(section => {return section.classification === 'patronat'}),
    };

    this.visibleSortedMenuSections = this.isCongressFuture
      ?  Sort.by_lft(this.sections.filter(section => {return section.showInMenu === true}))
      :  Sort.by_lft(this.sections.filter(section => {return section.showInMenu === true && section.classification !== 'ticket'}))


    this.parsedDate = Parse.durationDates(this.starting, this.finishing, 'DD.MM.YYYY');
    this.sponsorsLogo = this.apiSections.sponsors ? this.apiSections.sponsors.sponsors[0].logos.find(logo => logo.showInMenu === true) : false;
  }
}