import React from 'react';
import WorkshopsAndEntertainment from './workshops_and_entertainment';
import SpecialOffer from './special_offer';
import MainSummary from './main_summary';

export default function PurchaseSummary(props) {
  const {
    anyFreeTicketsLeft,
    allowMultipleWorkshops,
    workshopProducts,
    conferenceRelatedProducts,
    entertainmentProduct,
    relatedProducts,
    specialOfferInfo,
    specialOfferProductInfo,
    mainProduct,
    entertainmentProductHeader
  } = props.data.ticketsData;

  const { close } = props;
  const date = props.data.currentPlace.parsedDate;

  return (
    <div className='purchase-summary' id='puchaseSummary'>
      <div className='purchase-summary__elem'>
        <MainSummary
          product={mainProduct}
          date={date}
          close={close}
        />
      </div>
      {(workshopProducts.length > 0 || entertainmentProduct) &&
      <div className={`purchase-summary__elem`}>
        <WorkshopsAndEntertainment
          workshopProducts={workshopProducts}
          entertainmentProduct={entertainmentProduct}
          anyFreeTicketsLeft={anyFreeTicketsLeft}
          allowMultipleWorkshops={allowMultipleWorkshops}
          entertainmentProductHeader={entertainmentProductHeader}
        />
      </div>
      }
      <div className={`purchase-summary__elem`}>
        {relatedProducts.length > 0 &&
        <SpecialOffer
          relatedProducts={relatedProducts}
          relatedInfo={specialOfferInfo}
        />
        }

        {conferenceRelatedProducts.length > 0 &&
          <SpecialOffer
            relatedProducts={conferenceRelatedProducts}
            relatedInfo={specialOfferProductInfo}
          />
        }
      </div>
    </div>
  )
}
