import React, {Component} from 'react';
import {Sort} from '../../helpers/sort';

export default class Invitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      short: window.isMobileView,
    };
  }

  _shortenText = (text) => {
    return text.substr(0, 200) + '...'
  };

  _handleClick = () => {
    this.setState({
      short: !this.state.short
    });
  };

  render() {
    const {invitationData} = this.props;
    const lecturerConnections = invitationData.lecturerConnections
    const invitation = {
      lecturers: Sort.by_lft(lecturerConnections),
      text: invitationData.htmlContent,
    };

    const textIsPresent = invitation.text !== '<p><br></p>' && invitation.text !== '';
    const text = invitation.text;
    const shortText = this._shortenText(text);
    let mobile = window.isMobileView;

    return (
        <div className='invitation page__section grid__padding-vertical'>
          <h3 className="section-title">
            Zaproszenie
          </h3>
          <div className="grid__container">
            <div className={`grid__row invitation__row ${!textIsPresent ? 'invitation__row_space' : ''}`}>
              {textIsPresent &&
                <div className="invitation__element">
                  <p className={`invitation__text text-extendable text_nun-light`} dangerouslySetInnerHTML={{__html: this.state.short ? shortText : text}}/>

                  {mobile &&
                  <button className={`button-details`} onClick={this._handleClick}>
                    {`${this.state.short ? 'Przeczytaj całość' : 'Zwiń'}`}
                  </button>
                  }
                </div>
              }
              <div className='invitation__lecturers'>
                {lecturerConnections.length > 0  && lecturerConnections.map((connection, index) =>
                  <div className=" invitation__lecturer " key={index}>
                    <div className='invitation__photo-container'>
                      <img src={`${connection.lecturer.mediumPhoto}`} alt={connection.lecturer.nameWithDegree}/>
                    </div>
                    <div>
                      <p className='invitation__text invitation__text_margined'>{connection.lecturer.degree}</p>
                      <p className='invitation__text invitation__text_margined'>{connection.lecturer.firstName} {connection.lecturer.lastName}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
  }
}