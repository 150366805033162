import React, {Component} from 'react';
import logo from '../../assets/images/magwet_logo.svg'
import {
  CartIcon, CloseIcon,
  HamburgerIcon, MagwetCommentsIcon,
  SearchIcon,
  UserLinesIcon
} from '../../assets/icons/icons'
import MenuElement from './header_lib/_menu_element';
import {HeaderMagwetMenu} from '../../models/congress_single/entity/HeaderMagwetMenu';
import SubscribeLink from './header_lib/_subscribe_link';

export default class HeaderMagwet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: this.props.isLoggedIn,
      numberOfItemsInCart: this.props.productsInCart,
      menuOpen: false,
      searchOpen: false,
      popupOpen: false,
      menuElements: {},
      loginMenuOpen: false,
    };

    this.header = this.props.ref
  }

  _handleMenuOpen = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  };

  _openLoginMenu = () => {
    this.setState({
      loginMenuOpen: true
    })
  }

  _closeLoginMenu = () => {
    this.setState({
      loginMenuOpen: false
    })
  }

  componentDidMount() {
    HeaderMagwetMenu.fetch('/menus/mw_header?include=subMenus')
        .then(resp => {
          this.setState({
            menuElements: resp
          })
        })
  }

  render() {
    let iconSize = window.isSmallView ? '22' : '27';
    const {menuElements} = this.state;

    return (
        <header className='header-magwet' ref={this.header}>
          <div className="grid__container">
            <div className='header-magwet__container'>
              <div className='header-magwet__separator d_none d_lg_block'/>
              <div className='header-magwet__item d_flex d_lg_none'>
                <button className='header-magwet__link menu-link menu-link_logo button'
                        onClick={this._handleMenuOpen}>
                  <SetMobileMenuIcon isOpen={this.state.menuOpen}/>
                </button>
              </div>

              <div className='header-magwet__item'>
                <a href='/' className='header-magwet__link menu-link menu-link_logo'>
                  <img src={logo} alt="Logo Magwet.pl" className='header-magwet__logo'/>
                </a>
              </div>

              <div className='header-magwet__item d_none d_lg_block'>
                <a href="/wpd" className='header-magwet__wpd-link'>
                  <span>Zobacz też ></span>
                  <p className='header-magwet__wpd-link_white'>Weterynaria po dyplomie</p>
                </a>
              </div>

              <div className='header-magwet__item header-magwet__item header-magwet__item_grow d_none d_lg_block'>
                <form action='/search'
                      className={`header-magwet__form ${this.state.searchOpen ? 'header-magwet__form_show' : ''}`}
                      method='get' role='search'>
                  <input id="navbarSearch" className="header-magwet__search-input" type='text' name='q'
                         placeholder='Szukaj'/>
                  <button type='submit' className='button button-search'>
                    <SearchIcon className='icon_white' width='32' height='32'/>
                  </button>
                </form>
              </div>

              <div className='header-magwet__item d_none d_lg_block'>
                <SubscribeLink/>
              </div>

              <div className='header-magwet__separator d_none d_lg_block'/>

              <div className={`mobile-menu ${this.state.menuOpen ? 'mobile-menu_show' : ''}`}>
                {/*MOBILE MENU START*/}
                <ul className='header-magwet__list mobile-menu__list'>

                  <li className='header-magwet__item d_block d_lg_none mobile-menu__item mobile-menu__item_no-border'>
                    <a href="/wpd" className='header-magwet__wpd-link'>
                      <span>Zobacz też ></span>
                      <p className='header-magwet__wpd-link_white'>Weterynaria po dyplomie</p>
                    </a>
                  </li>

                  <li className='header-magwet__item header-magwet__item header-magwet__item_grow d_block d_lg_none mobile-menu__item mobile-menu__item_no-border'>
                    <form action='/search'
                          className={`header-magwet__form ${this.state.searchOpen ? 'header-magwet__form_show' : ''}`}
                          method='get' role='search'>
                      <input id="navbarSearch" className="header-magwet__search-input" type='text' name='q'
                             placeholder='Szukaj'/>
                      <button type='submit' className='button button-search'>
                        <SearchIcon className='icon_white' width='32' height='32'/>
                      </button>
                    </form>
                  </li>

                  <li className='header-magwet__item d_block d_lg_none mobile-menu__item mobile-menu__item_no-border'>
                    <SubscribeLink/>
                  </li>

                  <li className='header-magwet__item header-magwet__item_society mobile-menu__item '>
                    <a href="/auth/users/new" className='menu-link menu-link_society'>
                      <MagwetCommentsIcon height='30' width='30' className='icon icon_white icon_margined'/>
                      Zostań członkiem społeczności <br/> MAGWET
                    </a>
                  </li>

                  {menuElements.length > 0 && menuElements.map((menuItem, index) =>
                      <MenuElement menuItem={menuItem} key={index}/>
                  )}

                  <li className='header-magwet__item d_block d_lg_none mobile-menu__item'>
                    <a href="/order" className='header-magwet__link menu-link'>
                      <CartIcon width={iconSize} height={iconSize} className='icon_white icon_margined'/>
                      <SetItemsInBadge numberOfItemsInCart={this.state.numberOfItemsInCart}/>
                      Koszyk
                    </a>
                  </li>

                  <li className='header-magwet__item d_block d_lg_none mobile-menu__item'>
                    <SetLoginContent isLoggedIn={this.state.isLoggedIn} />
                  </li>
                </ul>

                <div className='header-magwet__separator_vertical'/>
              </div>

              <div className='header-magwet__separator_vertical'/>

              <div className='header-magwet__item'>
                <a href="/order" className='header-magwet__link menu-link'>
                  <CartIcon width={iconSize} height={iconSize} className='icon_white'/>
                  <SetItemsInBadge numberOfItemsInCart={this.state.numberOfItemsInCart}/>
                </a>
              </div>

              <div className='header-magwet__item header-magwet__item_relative d_flex'
                   onMouseEnter={this._openLoginMenu}
                   onMouseLeave={this._closeLoginMenu}>
                <SetLoginContent
                    isLoggedIn={this.state.loggedIn}
                    iconSize={iconSize}
                    loginMenuOpen={this.state.loginMenuOpen}
                />
              </div>
            </div>
          </div>
        </header>
    )
  }
}

function SetLoginContent(props) {
  const {isLoggedIn, loginMenuOpen, iconSize} = props;

  if (!window.isSmallView) {
    return (
        <div className='header-magwet__link menu-link'>
          <UserLinesIcon className='icon_white icon_margined' width={iconSize} height={iconSize}/>
          {loginMenuOpen && isLoggedIn &&
          <div className='dropdown dropdown_show dropdown_right'>
            <a href='/auth/account/user' className='dropdown__item'>
              Moje konto
            </a>
            <a href='/logout' className='dropdown__item'>
              Wyloguj
            </a>
          </div>
          }
          {loginMenuOpen && !isLoggedIn &&
          <div className='dropdown dropdown_show dropdown_right'>
            <a href='/auth/login' className='dropdown__item'>
              Zaloguj
            </a>
            <a href='/auth/users/new' className='dropdown__item'>
              Zarejestruj
            </a>
          </div>
          }
        </div>
    )
  } else {
    return (
        <div className='header-magwet__link menu-link'>
          {isLoggedIn &&
            <a href='/auth/account/user' className='menu-link'>
              MOJE KONTO
            </a>
          }
          {!isLoggedIn &&
            <a href='/auth/account/user' className='menu-link'>
              ZALOGUJ
            </a>
          }
        </div>
    )
  }

}

function SetItemsInBadge(props) {
  let numberOfItems = props.numberOfItemsInCart;

  return (
      <span className='menu-link__badge'>
        {numberOfItems}
      </span>
  )
}

function SetMobileMenuIcon(props) {
  return props.isOpen ? <CloseIcon className='icon_white' width='26' height='26'/> :
      <HamburgerIcon className='icon_white' width='26' height='26'/>;
}
