import React, {Component} from 'react';
import Popup from "./popups/popup";
import {PlayIcon} from '../../assets/icons/icons';
import avatar from '../../assets/images/avatar.png'
import {Sort} from '../../helpers/sort';

export default class Lecturers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    }
  }

  _handleLecturersListExtension = () => {
    this.setState({extended: !this.state.extended});
  };

  render() {
    const isSmallView = window.isSmallView;
    const {lecturersData} = this.props;

    const lecturerConnections = lecturersData.lecturers[0].lecturerConnections;

    const lecturerConnectionsWithPriority = lecturerConnections.filter(connection => {return connection.priorityShow === true});
    const priorityLecturersConnectionsByLft = Sort.by_lft(lecturerConnectionsWithPriority);

    const lecturerConnectionsWithoutPriority = lecturerConnections.filter(connection => {return connection.priorityShow === false});
    const sortedLecturersConnectionsWithoutPriority = Sort.nested_by_lastname(lecturerConnectionsWithoutPriority, 'lecturer');

    return (
        <div className='lecturers page__section' id='lecturer'>
          <h3 className="section-title">
            Wykładowcy
          </h3>
          <div className="grid__row">
            {(this.state.extended && priorityLecturersConnectionsByLft.length > 0) && priorityLecturersConnectionsByLft.map((connection, index) =>
                    <div className='grid__col_6 grid__col_sm_3' key={index}>
                      <LecturerCard lecturer={connection.lecturer}/>
                    </div>
            )}
            {(this.state.extended && sortedLecturersConnectionsWithoutPriority.length > 0) && sortedLecturersConnectionsWithoutPriority.map((connection, index) =>
                <div className='grid__col_6 grid__col_sm_3' key={index}>
                  <LecturerCard lecturer={connection.lecturer}/>
                </div>
            )}
            {(!this.state.extended && priorityLecturersConnectionsByLft.length > 0) && priorityLecturersConnectionsByLft.map((connection, index) =>
                <div className='grid__col_6  grid__col_sm_3' key={index}>
                  <LecturerCard lecturer={connection.lecturer}/>
                </div>

            )}
          </div>
          <div className='lecturers__center-row'>
            <button className='button button-flex lecturers-button'
                    onClick={this._handleLecturersListExtension}>
              {this.state.extended ? 'Zwiń' : 'Rozwiń pełną'} listę {isSmallView ? '' : 'wykładowców'}
              <span
                  className={`arrow arrow-sm ${this.state.extended ? 'arrow-sm_up' : 'arrow-sm_down'} lecturers-button__arrow`}/>
            </button>
          </div>
        </div>
    )
  }
}

class LecturerCard extends Component {
  constructor(props) {
    super(props);
    this.button = React.createRef();
    this.state = {
      isPopupVisible: false
    }
  }

  _handleHover = () => {
    let button = this.button.current;
    button.classList.add('lecturer-card__cta_show')
  };

  _handleMouseLeave = () => {
    let button = this.button.current;
    button.classList.remove('lecturer-card__cta_show')
  };

  _togglePopup = () => {
    this.setState({
      isPopupVisible: !this.state.isPopupVisible
    })
  };

  render() {
    const {lecturer} = this.props;

    return (
        <div className='lecturer-card lecturers__card' onMouseEnter={this._handleHover} onMouseLeave={this._handleMouseLeave} onClick={this._togglePopup}>
          {lecturer.videoUrl.length > 0 &&
              <PlayIcon width='30' height='30' className='lecturer-card__play-icon'/>
          }
          <img src={lecturer.mediumPhoto !== '/images/lecturers/medium/missing.png' ? lecturer.mediumPhoto : avatar} alt={`Zdjęcie ${lecturer.nameWithDegree}`} className='lecturer-card__image'/>
          <p className='lecturer-card__name'>
            {lecturer.nameWithDegree}
            <button className='button button-flex lecturer-card__cta' ref={this.button} >
              Czytaj więcej
              <span className='arrow arrow-sm arrow-sm_right'/>
            </button>
          </p>
          {this.state.isPopupVisible &&
            <Popup data={lecturer} type='lecturer' popupShow={this.state.isPopupVisible} closePopup={this._togglePopup}/>
          }
        </div>
    )
  }
}