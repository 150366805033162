import React, {Component} from 'react';
import Popup from '../popups/popup';

export default class MarketingAlertTicket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupShow: false
    }
  }

  closePopup = () => {
    this.setState({popupShow: false})
  }

  openPopup = () => {
    this.setState({popupShow: true})
  }

  render() {
    const {data, popupData} = this.props;
    return (
        <div className='marketing-alert marketing-alert-ticket'>
          <p dangerouslySetInnerHTML={{__html: data}}/>
          <button onClick={this.openPopup} className='button button-sm button-white'>
            Więcej
          </button>
          <Popup closePopup={this.closePopup}
                 type='promoCoupon'
                 data={popupData}
                 popupShow={this.state.popupShow}/>
        </div>

    )
  }
}

