import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import {BookIcon} from '../../../assets/icons/icons';
import {base_url} from '../../../api/Api';
import PurchaseSummary from '../ticket_purchase/purchase_summary'

export default class PopupContents extends Component {
  static SubscribePopupContent = (data) => {
    let links = data.subscribe.links;
    return (
        <>
          <div className="subscribe-popup__header">
            <div className="subscribe-popup__icon">
              <BookIcon width='36' height='36'/>
            </div>
            <div className="subscribe-popup__text">
              Kontynuuj lub
              <br/>
              Zamów prenumeratę
            </div>
          </div>
          <ul className="subscribe-popup__list">
            {links && links.map((link, index) =>
                <li className="subscribe-popup__item" key={index}>
                  <a href={`${base_url}${link.path}`} className="subscribe-popup__link">
                    <p className="subscribe-popup__title">
                      {link.name}
                    </p>
                    <button className="subscribe-popup__button button button-success">
                      Sprawdź
                    </button>
                  </a>
                </li>
            )}
          </ul>
        </>
    )
  };

  static LecturerPopupContent = (data) => {
    return (
        <>
          <div className="grid__row">
            <div className="grid__col_12 grid__col_md_3">
              <img src={data.mediumPhoto} alt={`Zdjęcie ${data.nameWithDegree}`} className='popup__image'/>
            </div>
            <div className='grid__col_12 grid__col_md_9'>
              <p className='popup__text'>
                {data.nameWithDegree}
              </p>
              <p className='popup__text popup__text_grey'>
                {data.description}
              </p>
            </div>
          </div>
          {data.videoUrl.length > 0 &&
            <div className="grid__row">
              <div className="grid__col_12 grid__col_md_3"/>
              <div className="grid__col_12 grid__col_md_9">
                <ReactPlayer
                    url={data.videoUrl}
                    playing
                    controls
                    width='100%'/>
              </div>
            </div>
          }
        </>
    )
  }

  static LiveStreamingInfoContent = (data) => {
    return (
        <div className="grid__row">
          <div className='grid__col_12'>
            <p className='popup__text popup__text_grey' dangerouslySetInnerHTML={{__html: data.description}}/>
          </div>
        </div>
    )
  }

  static PreviousEditionContent = (data) => {
    return (
        <div className="grid__row">
          <div className="grid__col_12">
            <ReactPlayer
                url={data.source}
                playing
                controls
                width={window.isSmallView ? 'auto' : '640px'}/>
          </div>
        </div>
    )
  }

  static MarketingAlertPopup = (data, close) => {
    return (
        <div className='grid__row'>
          <div className="grid__col_12">
            <div className='popup__text popup__text_grey'>
              <div dangerouslySetInnerHTML={{__html: data}}/>
              <div className='popup__center-row'>
                <button className='button button-sm button-success' onClick={close}>
                  Zamknij
                </button>
              </div>
            </div>
          </div>
        </div>
    )
  };

  static TooltipContent = (data) => {
    return (
        <div className="grid__row">
          <p className='popup__text popup__text_grey' dangerouslySetInnerHTML={{__html: data.tooltip}}/>
        </div>
    )
  };

  static PurchaseContent = (data, close) => {
    return (
      <PurchaseSummary data={data} close={close}/>
    )
  };

  static LectureContent = (data) => {
    return (
      <div className='grid__row'>
        <div className='grid__col_12'>
          <p className='popup__text popup__text_grey'>
            {data}
          </p>
        </div>
      </div>
    )
  };
}