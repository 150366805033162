import React, {Component} from 'react';
import moment from 'moment';
import MarketingAlertHeader from './marketing_alerts/marketing_alert_header';
import HTMLContentHelper from '../../helpers/html_content_helper';

export default class HeaderSticker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stickerState: 'none' //firstMinute, lastMinute, expired, marketingAlertPresent none
    };

    this.data = this.props.data;
    this.currentPool = this.data.tickets[0].currentPool;
  }

  _setStickerState = (pool) => {
    const dateNow = moment();
    let startDate, endDate;

    if (pool !== undefined) {
      startDate = moment(pool.ticketProduct.campaignStartAt);
      endDate = moment(pool.ticketProduct.campaignEndAt);
    }

    if (pool === undefined) {
      this.setState({stickerState: 'expired'});
    } else if (HTMLContentHelper.hasHtmlStringInnerText(this.props.marketingAlertHeader)) {
      this.setState({stickerState: 'marketingAlertPresent'})
    } else if (endDate.diff(dateNow, 'days') < 7) {
      this.setState({stickerState: 'lastMinute'})
    } else if (dateNow.diff(startDate, 'days') < 7) {
      this.setState({stickerState: 'firstMinute'})
    } else {
      this.setState({stickerState: 'none'})
    }
  };

  _setTextInSticker = () => {
    switch(this.state.stickerState) {
      case 'expired':
        return 'Ten kongres już się odbył';
      case 'firstMinute':
        return 'Ruszyła sprzedaż nowych biletów!';
      case 'lastMinute':
        return 'Ostatnie dni tańszych biletów!';
      default:
        return '';
    }
  };

  componentDidMount() {
    this._setStickerState(this.currentPool)
  }

  render() {
    const innerText = this._setTextInSticker();

    if(this.state.stickerState === 'marketingAlertPresent') {
      return (
          <MarketingAlertHeader data={this.props.marketingAlertHeader}/>
      )
    } else {
      return (
          <div>
            {this.state.stickerState !== 'none' &&
            <div className='header__sticker promo-sticker'>
              {innerText}
            </div>
            }
          </div>
      )
    }
  }
}